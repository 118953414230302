
/* --------article1-----------*/
.top_strip{
    background-color: #fecf67;
    padding: 40px;
    margin-bottom: 50px;
}
.top_strip h2 {
    text-align: center;
    margin: 0;
    font-size: 30px;
    text-transform: uppercase;
    color: #b48c35;
    font-weight: 700;
}
/* --------article2-----------*/
.company-overview{
    width:100%;
    padding:50px 50px 0px 50px;
}
.pc-holder {
    border: solid 1px #999;
    padding: 8px;
    height:400px;
    width:350px;
}
.overview{
    padding-left: 30px;
}
.overview ul{
    height:180px;
    font-size:18px;
  }
  .overview ul li{
     padding:2px;
  }

.overview h2 {
    font-size: 20px;
    color: #d51d1d;
    text-transform: uppercase;
    margin-left:10px; 
}
.about-content{
    display:flex;
    justify-content: center;
    width:90%;
    padding-left:150px;
}


@media only screen and (max-width: 928px) { 
    .about-content{
        display:flex;
        flex-direction: column;
        padding:0px;
        padding-left:5px;
    }
    .overview{
      padding:0px;
      margin-top:5px;
      margin-bottom:200px;
    }
    .overview ul{
        width:100%;
        font-size:18px;
        margin-bottom: 40px;
        padding-right: 20px;
    }
    .company-overview{
        padding:0px;
    }
}


/*------article3----------*/
.about-us-vision{
    background-color: #fecf67;
    width:100%;
    margin-top:120px;
    padding-left:110px;
}
.our-vision{
   font-size:22px;
}
.our-vision-para{
    font-size:18px;
    font-weight:450;
}
.about-us-vision h4::after {
    content: '';
    width: 30px;
    height: 3px;
    background-color: #333;
    display: block;
    border-radius: 40px;
    margin-top: 10px;
}
@media only screen and (max-width: 928px) { 
    .about-us-vision{
        padding-left:0px;
        padding:10px;
        margin-top:200px;
    }
}

/*------article4----------*/
.about_btm {
    width:100%;
    margin-top: 40px;
    margin-bottom: 40px;
}
.about_btm h2{
    font-size:32px;
}
.about_btm h3 {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #d51d1d;
}
.about_btm h4 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 5px;
}
.about_btm h5 {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
}
.about_btm h5::after {
    content: '';
    width: 20px;
    height: 3px;
    display: block;
    background-color: #d51d1d;
    margin-top: 10px;
}
.about_btm p {
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 15px;
}

.about_btm_inner {
    padding:18px;
    border: solid 1px #efefef;
}
.about_btm_inner img {
    max-width: 100%;
}
