/* {article1} */
.top_strip{
    background-color: #fecf67;
    padding: 40px;
    margin-bottom: 50px
}
.top_strip h2 {
    text-align: center;
    margin: 0;
    font-size: 30px;
    text-transform: uppercase;
    color: #b48c35;
    font-weight: 700;
}

/*article2*/
.services{
    width:100%;
    padding-left:180px;
    padding-right:180px;
}
@media only screen and (max-width:768px){
    .services{
        width:100%;
        padding-left:0px;
        padding-right:0px;
    }
}
.services h3 {
    font-size: 24px;
    margin-top: 25px;
}
.services h3::after {
    content: '';
    width: 70px;
    height: 2.5px;
    background-color: #f00;
    display: block;
    border-radius: 50px;
    margin-top: 5px;
}
.services p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size:16px;
}
.services img {
    max-width: 100%;
}

/* article3 */
.qr-code{
    width:100%;
    margin-top:30px;
    padding-left:180px;
    padding-right:180px;
}
.qrcodecolor{
    background-color: #fecf67;
}
@media only screen and (max-width:928px){
    .qr-code{
        padding-left:0px;
        padding-right:0px;
    } 
}
.code-basing {
    padding:30px;
}
.code-basing h3 {
    font-size: 24px;
    margin-top: 25px;
}
.code-basing h3::after {
    content: '';
    width: 70px;
    height: 2.5px;
    background-color: #f00;
    display: block;
    border-radius: 50px;
    margin-top: 5px;
}
.code-basing p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size:16px;
    font-weight:800px;
}
.code-basing img {
    max-width: 100%;
}