/* --------article1-----------*/
.top_strip{
    background-color: #fecf67;
    padding: 40px;
    margin-bottom: 50px
}
.top_strip h2 {
    text-align: center;
    margin: 0;
    font-size: 30px;
    text-transform: uppercase;
    color: #b48c35;
    font-weight: 700;
}

/* --------article2-----------*/
.map-contact{
    width:100%
}
.contact_us_frm h2 {
    font-size: 22px;
    margin: 0;
}
.contact_us_frm h2::after {
    content: '';
    width: 78px;
    height: 2px;
    background-color: #f00;
    display: block;
    margin-top: 10px;
}
.contact_us_frm{
    padding:0px;
    margin:0px;
}
.contact_us_frm h5 {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 15px;
}
.free-quote-name{
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
    padding:16px;
    height:400px;

}
.name{
    outline:none;
    border-width:0px;
    width:100%;  
    padding-top: 18px;  
    font-size:16px;
    border-bottom: solid 1px #8c8a84;
}
@media screen and (max-width:928px){
    .contact_us_frm{
       margin-top:20px;
       margin-left:12px;
       margin-right:50px;
    }
    
}

/* --------article3-----------*/
.mail-website{
    width:100%;
    margin-top:60px;
    margin-bottom:60px;
}
.mail-image{
    color:red;
    background-color:red;
    width:60px;
    border-radius:50px;
    margin-left:100px;
}
.mail-image:hover{
    background-color:black;
   
}
.mail-container{
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
    border-style:solid;
    border-width:1px;
    background-color:#F5F5F5;
    width:250px;
    text-align:center;
}
.mail-container1{
    padding-bottom:17px;
}
.mail-container h1{
    font-size:18px;
    font-weight:bold;
    font-family:"Roboto";
    padding:16px;
}
.mail-container p{
    font-size:12px;
}
.contact-datas{
    display:flex;
}
.container-image{
    margin-right:100px;
    padding:16px;
}
@media screen and (max-width:768px){
    .contact-datas{
        display:flex;
        flex-direction: column;
    }
    .container-image{
        margin-top:20px;
        margin-left:45px;

    }
}