
.forgot-container{
    display: grid;
    place-content: center;
}
.forgot-container1{
    box-shadow:5px 5px 50px 0px rgba(0,0,0,0.1);
    padding:24px;
    margin-top:120px;
    margin-bottom:120px;
    text-align:center;
}
.forgot-container1 h2 {
    color: #d51d1d;
    font-size:26px;
}
.forgot-container1 p{
    font-size:16px;
}
.emailname{
    width:100%;
    outline:none;
    border-width:0px;
    border-bottom:solid 1px #8c8a84;
 }