/* --------article1-----------*/
.top_strip{
    background-color: #fecf67;
    padding: 40px;
    margin-bottom: 50px
}
.top_strip h2 {
    text-align: center;
    margin: 0;
    font-size: 30px;
    text-transform: uppercase;
    color: #b48c35;
    font-weight: 700;
}