.invoice-container{
   margin-top:50px;
}
.invoice-container-content{
    margin-top:10px;
    padding-left:16px;
}
.invoice-img{
    height:60px
}
.invoice-heading{
  color:#f5ce42;
  font-size:18px;
  font-weight:bold;
  padding: 0px;
  margin:0px;
  margin-left:20px;
}
.invoice-desc{
    color:#bf0d0d;
    font-size:12px;
}
.invoice-container-divs{
   margin:46px 46px 0px 46px;
}
.item1{
    height:30px;
    width:20%;
    background-color:#f5ce42;
}
.item2{
    height:30px;
    width:80%;
    background-color:#bf0d0d;
}
.item3{
    height:30px;
    width:20%;
    background-color:#f5ce42;
}
.invoice-container-link{
    margin-left:46px;
}
.table-container{
    margin-left:46px;
    margin-right:46px;
}
.table-styled{
    width:100%
}
.annexture{
    text-align:center;
}
.director-sign{
    padding:16px;
}
.empt-row{
    padding:10px;
}
.download-buttom{
    position:relative;
    right:50px;
    top:80px;
   
}
.download-pdf{
    border-width:0px;   
}