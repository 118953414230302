/* --------article1-----------*/
.login_left{
    padding:8px;
}
.login_left b {
    color: #f00;
    font-weight: bolder;
}
.login_left p {
    font-size:15px;
}
.login_right{
    padding:14px;
    background-color: #E7C52E;
}
.login_right h1{
    font-size:22px;
    font-weight:bold;
    text-align:center;
}
.login_right p{
    font-size:14px;
    text-align:center;
    font-weight:650;
}
.login_right label{
    color:white;
    font-size:14px;
    font-weight:600;
}
.username{
    border-width: 0px;
    width:100%;
    outline: none; 
    font-size:14px;
    height:36px;
    background-color:#E7C52E;
    color:#000000;
    font-weight:bold;   
}
.captchaimg{
    margin-top:10px;
}
/* @media screen and (max-width:768px){
    .username{
        width: 100%;
    }
} */