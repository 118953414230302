/* Navbar.css */
/*--------------------Header--------------*/

header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    padding: 8px;
    position:sticky;
    top:0; 
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
    width:100%;
    z-index: 1000;
  }
  .dot-header{
    font-size: 20px;
    font-weight: bold;
    color:#999;
  }
  .user-icon{
    width:14px;
  }
  
  .logo {
    /* Style your logo or other content */
    margin-right: 10px;
  }

  .menu-icon {
    cursor: pointer;
    display: none; /* Initially hide the menu icon */
  }
  
  nav {
    display: flex;
    justify-content: space-around;
  }
  
  .Link {
    color: black;
    text-decoration: none;
    padding: 10px;
    font-size:16px;
  }

  .Link:hover{
    color: red;
  }
  


  
  
  /* Media query for smaller screens (e.g., mobile) */
  @media only screen and (max-width: 928px){
    .menu-icon {
      display: block; /* Show the menu icon on smaller screens */
    }
    .dot-header{
      display: none;
    }
    nav {
      flex-direction: column;
      align-items: center;
      display: none; /* Initially hide the navigation links */
      width: 100%;
      position: absolute;
      top: 60px; /* Adjust the top position based on your header height */
      left: 0;
      background-color: white;
    }
  
    nav.show {
      display: flex; /* Show the navigation links when the menu is clicked */
    }
  
    .Link {
      margin-top: 5px;
    }
    
  }
    
.react-slideshow-container button{
  display: none !important;
}

/*--------------------Header--------------*/

/* -------- hsrp-section styles------------- */

.hsrp-section{
  width:100%;
}
.hsrp-section h2{
    font-weight: normal;
} 
.section-2{
    text-align: center;
    font-size: 0.8rem;
    margin: 80px;
}
.section-2-content{
  font-size:16px;
}
.section-2 span{ 
    color:rgb(205, 37, 37);
    font-weight: normal;
}

.img-container{
   margin:50px;
   display:flex;
   justify-content:center;
}
.oem-img{
  height:145px;

}
.img-container h3{
    color:rgb(205, 37, 37);
    margin:8px;
    font-size:15px;
    font-weight:700;
}
.section-2-center-image{
   margin-left:170px;
   margin-right:170px;
}

@media only screen and (max-width: 768px) {
    .section-2-content{
        margin:10px;
    }
    .img-container{
      display:flex;
      flex-direction: column;
    }
    .section-2-center-image{
      margin-left:0px;
      margin-right:0px;
   }
  }
  /* -------- hsrp-section styles------------- */

  /* -------- hsrp-section1 styles------------- */
  .hsrp-section1{
    margin-top: 70px;
    margin-bottom: 70px ;
    display:flex;
    height:20vh;
    width:100%;
  }
  .hsrp-section1-container{
    background-image: url('../image/bg.jpg');
    background-position: 10% 30%;
    padding:30px;
    color:white;
    width:60%;
    font-size:16px;
  }
  .left button{
    background-color: #fecf67;
    border-radius: 8px;
    color: #000;
    border: none;
    display: table;
    margin: 0 auto;
    padding: 7px 35px;
    font-weight: 500;
    font-size: 18px;
}
 .right{
    background-color: #000;
    width:40%;
    color:white;
}
.right h4{
    color:#FECF67;
    font-size: 24px;
    margin:20px 0px 20px 0px;
}
.right-button-slide{
  border-radius:50%;
  background-color: white;
  border-width:0px;
  height:8px;
  width:8px;
  margin: 5px;
  position:relative;
  top:-15px;
}
.my-carousel-indicators button{
  margin-top: 85px;
  margin-left:5px;
}
.my-carousel-indicators{
  display: flex;
  place-content: center;
}
.slider-button {
  background-color: white;
}
.slider-button.active {
  background-color: red; /* Change background color to red */
}

@media (max-width: 768px) {
  .hsrp-section1{
    height:40vh;
  }
  .left button{
    background-color: #fecf67;
    border-radius: 8px;
    color: #000;
    border: none;
    display: table;
    margin: 0 auto;
    padding: 7px 35px;
    font-weight: 500;
    font-size: 14px;
    font-weight:bold;
}
}
 /* -------- hsrp-section1 styles------------- */

/* -------- hsrp-section2 styles------------- */
.hsrp-section2 {
  width:100%;
}
.how_we_work h2{
  text-transform: uppercase;
  text-align: center;
  font-size: 25px;
  font-weight: normal;
  margin-bottom: 10px;
}
.how_we_work h2 span {
  color: #de4c4c;
  font-weight: normal;
}
.how_we_work p {
  text-align: center;
  font-size: 16px;
  width:100%;
}
.services-card{
  text-align:center;
}
.sevices-img{
  height:1000px;
  margin-bottom:30px;
}
@media only screen and (max-width: 768px) {
  .how_we_work p {
    text-align: center;
    font-size: 16px;
    width:100%;
    padding:5px;
  }
  .sevices-img{
    height:350px;
    width:350px;
  }
}
/* -------- hsrp-section2 styles------------- */

/* -------- about_us styles------------- */
.about_us{
  margin-top:10px;
  padding:3px;
  width:100%;
}
.about_us-container{
 text-align:center;
 font-weight:normal;
}
.about_us-container h2{
  font-weight:normal;
}
.about_us-container span{
  color:#de4c4c;
  font-weight:normal;
}
.founded-desc{
  font-size:18px;
}
.space-content{
  font-size:13px;
}
.about_us-container button {
  border: none;
  color: #fff;
  background-color: #de4c4c;
  padding: 10px 35px;
  font-size: 16px;
  margin-bottom: 60px;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  .space-content{
    font-size:14px;
    margin:0px;
    padding-right:16px;
    padding-left:16px;
  }
  
}
/* -------- about_us styles------------- */

/*----------testimonial-----------------*/
.testimonial{
  background-image: url('../image/bg-1.jpg');
  height:25vh;
  width:100%;
  background-size: cover;
  padding:130px;
}
.testimonial_content{
    padding: 0 50px 20px 50px;
    color: #fff;
    text-align: center;
    position: relative;
    font-size:16px;
    font-weight:500;
}
.testimonial_content::before {
  content: '';
  background-image: url('../image/block-quote.png');
  width: 30px;
  height: 27px;
  position: absolute;
  left: 7px;
  top: 5px;
}
@media only screen and (max-width: 768px) {
  .testimonial{
    height:58vh;
    padding:24px;
  }
}
/*----------testimonial-----------------*/

/*-------footer-------------*/
.footer{
  background-color: #1f2125;
  width:100%;
}
.foot-logo{
  display: block;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}
.Link-footer, .Link-footer1 {
  text-decoration: none;
  color: #999;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 13px;
  position: relative;
}
.Link-footer::after {
  content: '|';
  color: #888;
  position: absolute;
  right: -2px;
  top: -1px;
}
.Link-footer:hover,
.Link-footer1:hover {
  color: white;
}
.btm_row {
  background-color: #000;
}
.btm_row span {
  color: #999;
  display: block;
  text-align: center;
  font-size: 12px;
  padding: 14px;
}
.footer-content-navbar{
  display:flex;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .footer-content-navbar{
    display:flex;
    flex-direction: column;
    text-align: center;
  }
  .foot-logo{
   padding-left:50px;
  }
  .Link-footer::after {
    display:none;
  }
}
/*-------footer-------------*/

