/* --------article1-----------*/
.top_strip{
    background-color: #fecf67;
    padding: 40px;
    margin-bottom: 50px
}
.top_strip h2 {
    text-align: center;
    margin: 0;
    font-size: 30px;
    text-transform: uppercase;
    color: #b48c35;
    font-weight: 700;
}

/* article 2 */
.accordian-content{
    width:100%;
    padding:10px 100px 100px 100px;
}
.accordion-item .accordion-button{
    background-color:#d51d1d;
    color:white;
    font-size: 16px;
    font-weight: 500;
}
.accordion-body {
    font-size: 15px;
    margin-bottom: 18px;
    line-height: 20px;
}